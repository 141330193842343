import React from 'react'
import physical from '../../img/mechanical.png'

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showVideo: false,
    }
    this.showVideoPlayer = this.showVideoPlayer.bind(this)
  }

  showVideoPlayer() {
    this.setState({ showVideo: true })
  }

  render() {
    const videoThumbnail = (
      <div className="video-thumbnail" onClick={this.showVideoPlayer}>
        <img width="460" height="315" src={physical} alt="Video thumbnail" />
        <div className="overlay">
          <span className="play-button"></span>
        </div>
      </div>
    )

    const videoPlayer = (
      <iframe
        width="460"
        height="315"
        src="https://www.youtube.com/embed/PM6sv1EhKg8?rel=0&amp;showinfo=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    )

    return <div>{this.state.showVideo ? videoPlayer : videoThumbnail}</div>
  }
}

export default VideoPlayer
