import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Modal from '../components/Modal'
// import SampleForm from '../components/Form'
// import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

// Images
import video1 from '../../static/video/skyway-seed.mp4'
import dice from '../img/dice.png'
import arrowsRepeat from '../img/arrows-repeat.png'
import trash from '../img/trash.png'
import downArrow from '../img/down-arrow.png'
import connection from '../img/connection.gif'
import folder from '../img/folder.gif'
import qrCode from '../img/qr-code.gif'
import saveMoney from '../img/save-money.gif'
import target from '../img/target.gif'
import physical from '../img/Physical.png'
import logoIcon from '../img/logo-icon.svg'

import VideoPlayer1 from '../components/videoPlayer/videoOne'
import VideoPlayer2 from '../components/videoPlayer/videoTwo'
import VideoPlayer3 from '../components/videoPlayer/videoThree'
import VideoPlayer4 from '../components/videoPlayer/videoFour'
import carousel1 from '../img/carousel-1.png'
import carousel2 from '../img/carousel-2.png'
import carousel3 from '../img/carousel-3.png'
import carousel4 from '../img/carousel-4.png'
import carousel5 from '../img/carousel-5.png'
import carousel6 from '../img/carousel-6.png'
import MySwiper from '../components/Swiper'
import ReactPlayer from 'react-player/youtube'
import videoBg from '../../static/video/seed-analysis.mp4'

import '../components/faq.css'

class SeedAnalytics extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Seed Analytics" description={siteDescription} />

        <div className="hero text-background-banner">
          <div style={{ position: 'relative', zIndex: '2' }}>
            <div className="row">
              <div className="col-md-8 offset-md-2 text-center">
                <h1 className="text-white">
                  Upgrade your seed analysis
                  <br />
                </h1>
                <h5 className="mt-0 text-white">
                  Fast, automatic and non-destructive results in seconds
                </h5>
                <Modal bsPrefix="btn-global" html="Test My Seed Samples">
                  <iframe
                    className="-0"
                    title="calendaly"
                    src="https://calendly.com/skywayanalytics/skyway-analytics-feasability-website-faq"
                    height="700px"
                    width="100%"
                    frame="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  />
                </Modal>
              </div>
            </div>
          </div>
          {/* <div className="hero-slider-bg">
            <ReactPlayer
              className="hero-video"
              url="https://www.youtube.com/watch?v=Qgkh2Ygxs4M"
              muted={true}
              playing={true}
              loop={true}
              width="202.78vh"
              height="114vh"
              style={{
                position: 'absolute',
                transform: 'transform: translate(-50%,-50%) scale(1.15);',
              }}
            />
          </div> */}
          <div
            style={{
              position: 'absolute',
              background: '#0000007a',
              width: '100%',
              height: '100%',
              top: '0',
              left: '0',
              objectFit: 'cover',
              zIndex: '1',
            }}
          />
          <video
            autoPlay
            loop
            muted
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '0',
              left: '0',
              objectFit: 'cover',
            }}
          >
            <source src={videoBg} type="video/mp4" />
            <track src="" kind="captions" srclang="en" label="English"></track>
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="container">
          <div className="row px-4">
            <div className="col-md-10 mx-auto text-center">
              <h2 className="pb-0 text-center mt-5">
                <img src={logoIcon} className="logo-icon" alt="Logo" />
                Spectral imaging reduces the variability and costs of visual
                seed inspection
              </h2>
              <p>
                Manual quality inspection methods are variable, destructive and
                costly — compared to automated spectral imaging methods
              </p>
              {/* 
              <div className="row text-center">
                <div className="col col-xl-3 mb-4 mx-auto">
                  <div className=" h-100 px-2 py-4">
                    <img
                      className="img-fluid mb-3 mx-auto"
                      width={80}
                      src={dice}
                      alt="Physical Purity"
                    />
                    <h5 className="m-0">
                      <b>Subjective</b> results depend on analyst and
                      environment
                    </h5>
                  </div>
                </div>
                <div className="col col-xl-3 mb-4 mx-auto">
                  <div className=" h-100 px-2 py-4">
                    <img
                      className="img-fluid mb-3 mx-auto"
                      width={80}
                      src={arrowsRepeat}
                      alt="shape distribution"
                    />
                    <h5 className="m-0">
                      <b>Destructive</b> tests waste seed
                    </h5>
                  </div>
                </div>
                <div className="col col-xl-3 mb-4 mx-auto">
                  <div className=" h-100 px-2 py-4">
                    <img
                      className="img-fluid mb-3 mx-auto"
                      width={80}
                      src={trash}
                      alt="Mechanical damage"
                    />
                    <h5 className="m-0">
                      <b>Expensive</b> to hire and train analysts to do
                      repetitive tasks
                    </h5>
                  </div>
                </div>
              </div> */}
              {/* <div className="row text-center">
                <div className="col col-xl-3 mb-4 mx-auto">
                  <div className="h-100 px-2 py-4">
                    <img
                      className="img-fluid mb-3 mx-auto"
                      width={80}
                      src={downArrow}
                      alt="Mechanical damage"
                    />
                  </div>
                </div>
              </div> */}
              <div className="row text-center">
                <div className="col-xl-4 mb-4 mx-auto">
                  <div className=" h-100 px-2 py-4">
                    <img
                      className="img-fluid mb-3 mx-auto"
                      width={80}
                      src={target}
                      alt="Physical Purity"
                    />
                    <h5 className="m-0">
                      <b>Objective</b> results regardless of person, time or
                      place
                    </h5>
                  </div>
                </div>
                <div className="col-xl-4 mb-4 mx-auto">
                  <div className=" h-100 px-2 py-4">
                    <img
                      className="img-fluid mb-3 mx-auto"
                      width={80}
                      src={qrCode}
                      alt="shape distribution"
                    />
                    <h5 className="m-0">
                      <b>Non-destructive</b> imaging keeps valuable seed in-tact
                    </h5>
                  </div>
                </div>
                <div className="col-xl-4 mb-4 mx-auto">
                  <div className=" h-100 px-2 py-4">
                    <img
                      className="img-fluid mb-3 mx-auto"
                      width={80}
                      src={connection}
                      alt="Mechanical damage"
                    />
                    <h5 className="m-0">
                      <b>Combine multiple tests</b> in a 3-5 second scan
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-xl-6 mb-4 mx-auto">
                  <div className=" h-100 px-2 py-4">
                    <img
                      className="img-fluid mb-3 mx-auto"
                      width={80}
                      src={saveMoney}
                      alt="Mechanical damage"
                    />
                    <h5 className="m-0">
                      <b>Reduce labor costs</b> and better <br /> utilize
                      analysts
                    </h5>
                  </div>
                </div>
                <div className="col-xl-6 mb-4 mx-auto">
                  <div className=" h-100 px-2 py-4">
                    <img
                      className="img-fluid mb-3 mx-auto"
                      width={80}
                      src={folder}
                      alt="Mechanical damage"
                    />
                    <h5 className="m-0">
                      <b>Record and access</b> traceable,
                      <br /> digital seed data
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center">
              <h2 className="pb-0 text-center mt-5">
                <img src={logoIcon} className="logo-icon" alt="Logo" />
                Measure what you care about
              </h2>
              <p>
                The following tests can all be simultaneously completed in under
                20 seconds
              </p>
            </div>
          </div>
          <div className="pt-5 justify-content-center text-center">
            <div className="row">
              <div className="col-md-6 mb-4">
                <VideoPlayer1 />
              </div>
              <div className="col-md-6 mb-4">
                <VideoPlayer2 />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-4">
                <VideoPlayer3 />
              </div>
              <div className="col-md-6 mb-4">
                <VideoPlayer4 />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-4">
                <a
                  href="https://calendly.com/skywayanalytics/skyway-analytics-feasability-website-faq"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    width="460"
                    height="315"
                    src={physical}
                    alt="Video thumbnail"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="row text-center py-5">
            <div className="col">
              <Modal bsPrefix="btn-global" html="Start a Feasibility Test">
                <iframe
                  className="-0"
                  title="calendaly"
                  src="https://calendly.com/skywayanalytics/skyway-analytics-feasability-website-faq"
                  height="700px"
                  width="100%"
                  frame="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
              </Modal>
            </div>
          </div>
        </div>
        <div className="container pb-5">
          <div className="row">
            <div className="col-md-10 mx-auto text-center">
              <h2 className="pb-0 text-center mt-5">
                <img src={logoIcon} className="logo-icon" alt="Logo" />
                Proven Results and 200+ Studies
              </h2>
              <p>
                Click each image to learn more about how spectral imaging is
                being used to streamline seed quality assessment around the
                world:
              </p>
              <div className="pt-5">
                <MySwiper slidesperview={1}>
                  <div>
                    <a
                      href="https://www.seedtest.org/api/rm/6Y6QP95BVUDK3D6/2-5-application-of-msi-technology-to-seed-physical.pdf"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        width="460"
                        height="315"
                        src={carousel1}
                        alt="Client One"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.seedtest.org/api/rm/ZDR58XFUT3UHJBR/2-9-2022-ista-dlf-g-lopez.pdf"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        width="460"
                        height="315"
                        src={carousel2}
                        alt="Client One"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://videometer.com/news/how-to-test-alfalfa-seed-vigor-in-a-non-destructive-way/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        width="460"
                        height="315"
                        src={carousel3}
                        alt="Client One"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://videometer.com/news/genetic-purity-assessment-on-pepper-seed/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        width="460"
                        height="315"
                        src={carousel4}
                        alt="Client One"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://videometer.com/news/seed-pathogen-detection-and-varietal-identification-in-wheat/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        width="460"
                        height="315"
                        src={carousel5}
                        alt="Client One"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C6&q=%22videometer%22+%22 seed%22&btnG="
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        width="460"
                        height="315"
                        src={carousel6}
                        alt="Client One"
                      />
                    </a>
                  </div>
                </MySwiper>
              </div>
            </div>
          </div>
          <div className="row text-center py-5">
            <div className="col">
              <a
                href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C6&q=%22videometer%22+%22seed%22&oq="
                className="btn-global"
                target="_blank"
                rel="noopener noreferrer"
              >
                View More Research
              </a>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SeedAnalytics

export const SeedAnalyticsQuery = graphql`
  query SeedAnalyticsQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
